@import 'scss-imports/variables';

/* Universal Overrides for Egret */

.hidden {
  display: none;
}

/* Navigation shorter and fixed to Top */
div.mat-toolbar {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 64px;
  padding: 0 16px;
  width: 100%;
}

.mat-mdc-tab-body-wrapper {
  padding: 16px;
}

/* Cards & Tables */
.mat-mdc-card-table {
  padding: 0 !important;
}

.mat-card-title-text {
  font-size: 24px;
  margin: 16px;
}

/* mat-sidenav */
mat-sidenav-content.fn-maincontent {
  transform: translate3d(0, 0, 0);
}

body.collapsed-menu mat-sidenav.mat-sidenav-closed mat-sidenav-content.fn-maincontent {
  margin-left: 48px !important;
  margin-right: 0 !important;
  transform: translate3d(0, 0, 0);
}

body.collapsed-menu mat-sidenav-container.mat-drawer-opened mat-sidenav-content.fn-maincontent {
  margin-left: 48px !important;
  margin-right: 0 !important;
  transform: translate3d(0, 0, 0);
}

.mat-sidenav.mat-sidenav-opened,
.mat-sidenav.mat-sidenav-opening {
  transform: translate3d(0, 0, 0);
}

/* side menu*/
.mat-nav-list .mat-mdc-list-item {
  display: block;
  height: auto !important;
}

.cdk-drop-list-dragging {
  cursor: grabbing;
}
