@font-face {
  font-display: swap;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 500;
  src: local('Titillium Web SemiBold'), local('TitilliumWeb-SemiBold'), url('../../fonts/TitilliumWeb/TitilliumWeb-SemiBold.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src: local('Titillium Web Bold'), local('TitilliumWeb-Bold'), url('../../fonts/TitilliumWeb/TitilliumWeb-Bold.ttf') format('truetype');
}
